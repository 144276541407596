/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  tracesSampleRate: window.ENV.SENTRY_ENV !== 'development' ? 1 : 0,
  replaysSessionSampleRate: window.ENV.SENTRY_ENV === 'production' ? 1 : 0,
  replaysOnErrorSampleRate: window.ENV.SENTRY_ENV === 'production' ? 1 : 0,
  environment: window.ENV.SENTRY_ENV,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
})

if (window.USER_CONTEXT) {
  Sentry.setUser({
    id: window.USER_CONTEXT.user.id,
    email: window.USER_CONTEXT.user.email,
    organizationId: window.USER_CONTEXT.organization.id,
    organizationName: window.USER_CONTEXT.organization.name,
  })
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
